import { CashSovereignsOptions, InvestorMetric } from './../../models/types';
import { PaiResults } from 'Features/PortfolioPai/PortfolioPai.hooks';
import { formatNumDecimals } from 'utils/numbers';

export enum PossibleErrors {
  marketValueMissing = 'MARKET_VALUE_MISSING',
  hasEmptyAnswer = 'HAS_EMPTY_ANSWER',
}

type InvesteeMetricCalculationFn = (
  companyPaiResult: PaiResults[number]
) => string | number | null | boolean | { [key: string]: number };

type InvestorMetricCalculationFn = (
  calculationFn: InvesteeMetricCalculationFn,
  results: PaiResults,
  cashAndSovereigns: CashSovereignsOptions
) => string | number | null;

const getOwnership = (result: PaiResults[number]) => {
  let ownership = Number(result.allocation) / Number(result.marketValue);
  if (isNaN(ownership)) ownership = 0;
  return ownership;
};

const addition = (values: number[]) => values.reduce((a, b) => a + b, 0);

const HELPER_FUNCTIONS = {
  addition,
  weighByInfluence: (calculationFn: InvesteeMetricCalculationFn, results: PaiResults) => {
    const values = results.map((res) => Number(calculationFn(res) ?? 0) * getOwnership(res));
    return values;
  },
  nonZero: (value: number) => (value === 0 ? 1 : value),
  parseNumberWithFallback: (value: number | string | null | undefined, fallbackValue = 0) => {
    const parsedNumber = Number(value);
    if (isNaN(parsedNumber)) return fallbackValue;
    return parsedNumber ?? fallbackValue;
  },
  hasSomeEmptyAnswer: (calculationFn: InvesteeMetricCalculationFn, results: PaiResults) =>
    results.some((r) => {
      const answer = calculationFn(r);
      if (r.allocation === 0) return false;
      return answer === null || answer === undefined || answer === '';
    }),
  getOwnership,
  getTotalValueOfInvestments: (results: PaiResults, cashAndSovereigns: CashSovereignsOptions) => {
    // CASH HERE

    const { cash, sovereigns } = cashAndSovereigns;

    const total =
      addition(
        results
          .map((res) => HELPER_FUNCTIONS.parseNumberWithFallback(res.allocation, 0))
          .concat([cash, sovereigns])
      ) ?? 1;

    return total;
  },
};

const INVESTEE_METRIC_CALCULATION_FUNCTIONS = {
  simpleMetric: (investeeMetric: string) => (companyPaiResult: PaiResults[number]) => {
    const answer = Number(companyPaiResult.answers[investeeMetric]);
    if (isNaN(answer)) return null;
    return answer;
  },
  valueByInfluence: (investeeMetric: string) => (companyPaiResult: PaiResults[number]) => {
    const investeeValue = Number.parseFloat(String(companyPaiResult.answers[investeeMetric] ?? 0));
    return investeeValue * HELPER_FUNCTIONS.getOwnership(companyPaiResult);
  },
  booleanMetric: (investeeMetric: string) => (companyPaiResult: PaiResults[number]) => {
    if (!companyPaiResult.answers[investeeMetric]) return null;
    return companyPaiResult.answers[investeeMetric] === 'YES' ? true : false;
  },
  sumMetric: (investeeMetrics: string[]) => (companyPaiResult: PaiResults[number]) => {
    return HELPER_FUNCTIONS.addition(
      investeeMetrics.map((m) =>
        HELPER_FUNCTIONS.parseNumberWithFallback(companyPaiResult.answers[m], 0)
      )
    );
  },
  divisionMetric:
    (numerator: string, denominator: string) => (companyPaiResult: PaiResults[number]) => {
      const numeratorValue = HELPER_FUNCTIONS.parseNumberWithFallback(
        companyPaiResult.answers[numerator],
        0
      );
      const denominatorValue = HELPER_FUNCTIONS.parseNumberWithFallback(
        companyPaiResult.answers[denominator],
        1
      );
      if (denominatorValue === 0) return null;
      return numeratorValue / denominatorValue;
    },
  divisionMetricMany:
    (numerators: string[], denominators: string[]) => (companyPaiResult: PaiResults[number]) => {
      const numeratorValue =
        HELPER_FUNCTIONS.addition(
          numerators.map((n) =>
            HELPER_FUNCTIONS.parseNumberWithFallback(companyPaiResult.answers[n], 0)
          )
        ) ?? 0;
      const denominatorValue =
        HELPER_FUNCTIONS.addition(
          denominators.map((d) =>
            HELPER_FUNCTIONS.parseNumberWithFallback(companyPaiResult.answers[d], 0)
          )
        ) ?? 1;

      if (denominatorValue === 0) return null;
      return numeratorValue / denominatorValue;
    },
  divisionMetricPerMillionEuros:
    (numerator: string, denominator: string) => (companyPaiResult: PaiResults[number]) => {
      const numeratorValue = HELPER_FUNCTIONS.parseNumberWithFallback(
        companyPaiResult.answers[numerator],
        0
      );
      const denominatorValue = HELPER_FUNCTIONS.parseNumberWithFallback(
        companyPaiResult.answers[denominator],
        1
      );
      if (denominatorValue === 0) return null;
      return numeratorValue / (denominatorValue * 0.000001);
    },
  // Specific metric calculation functions
  totalGHG: (companyPaiResult: PaiResults[number]) => {
    const scope1 = HELPER_FUNCTIONS.parseNumberWithFallback(companyPaiResult.answers.scope1, 0);
    const scope2 = HELPER_FUNCTIONS.parseNumberWithFallback(companyPaiResult.answers.scope2, 0);
    const scope3 = HELPER_FUNCTIONS.parseNumberWithFallback(companyPaiResult.answers.scope3, 0);
    return (scope1 + scope2 + scope3) * HELPER_FUNCTIONS.getOwnership(companyPaiResult);
  },
  carboonFootprint: (companyPaiResult: PaiResults[number]) => {
    const scope1 = HELPER_FUNCTIONS.parseNumberWithFallback(companyPaiResult.answers.scope1, 0);
    const scope2 = HELPER_FUNCTIONS.parseNumberWithFallback(companyPaiResult.answers.scope2, 0);
    const scope3 = HELPER_FUNCTIONS.parseNumberWithFallback(companyPaiResult.answers.scope3, 0);
    return (scope1 + scope2 + scope3) * HELPER_FUNCTIONS.getOwnership(companyPaiResult);
  },

  energyConsumptionHighImpact: (companyPaiResult: PaiResults[number]) => {
    const numeratorValue = HELPER_FUNCTIONS.parseNumberWithFallback(
      companyPaiResult.answers.totalEnergyconsumptionHighImpact,
      0
    );
    const denominatorValue = HELPER_FUNCTIONS.parseNumberWithFallback(
      companyPaiResult.answers.totalRevenueHighImpact,
      1
    );
    if (denominatorValue === 0) return null;
    return (numeratorValue * 0.001) / (denominatorValue * 0.000001);
  },
  energyConsumptionHICS:
    ({ revenueMetric, consumptionMetric }: { revenueMetric: string; consumptionMetric: string }) =>
    (companyPaiResult: PaiResults[number]) => {
      const numeratorValue = HELPER_FUNCTIONS.parseNumberWithFallback(
        companyPaiResult.answers[consumptionMetric],
        0
      );
      const denominatorValue = HELPER_FUNCTIONS.parseNumberWithFallback(
        companyPaiResult.answers[revenueMetric],
        1
      );
      if (denominatorValue === 0) return null;
      return (numeratorValue * 0.001) / (denominatorValue * 0.000001);
    },
  waterConsumed: (companyPaiResult: PaiResults[number]) => {
    const numeratorValue = HELPER_FUNCTIONS.parseNumberWithFallback(
      companyPaiResult.answers.waterConsumed,
      0
    );
    const denominatorValue = HELPER_FUNCTIONS.parseNumberWithFallback(
      companyPaiResult.answers.revenue,
      1
    );
    if (denominatorValue === 0) return null;
    return numeratorValue / (denominatorValue * 0.000001);
  },
  nAccidents_In: (companyPaiResult: PaiResults[number]) => {
    const rateOfAccidents = HELPER_FUNCTIONS.parseNumberWithFallback(
      companyPaiResult.answers.nAccidents,
      0
    );
    const numberOfEmployees = HELPER_FUNCTIONS.parseNumberWithFallback(
      companyPaiResult.answers.totalEmployees,
      1
    );
    const revenue = HELPER_FUNCTIONS.parseNumberWithFallback(companyPaiResult.answers.revenue, 1);
    return rateOfAccidents / numberOfEmployees / (revenue * 0.000001);
  },
  ghgIntensity: (companyPaiResult: PaiResults[number]) => {
    const scope1 = HELPER_FUNCTIONS.parseNumberWithFallback(companyPaiResult.answers.scope1, 0);
    const scope2 = HELPER_FUNCTIONS.parseNumberWithFallback(companyPaiResult.answers.scope2, 0);
    const scope3 = HELPER_FUNCTIONS.parseNumberWithFallback(companyPaiResult.answers.scope3, 0);
    const revenue = HELPER_FUNCTIONS.parseNumberWithFallback(companyPaiResult.answers.revenue, 1);
    return (scope1 + scope2 + scope3) / (revenue * 0.000001);
  },
  waterRecycled: (companyPaiResult: PaiResults[number]) => {
    const waterConsumed = HELPER_FUNCTIONS.parseNumberWithFallback(
      companyPaiResult.answers.waterConsumed,
      1
    );
    const waterRecycled = HELPER_FUNCTIONS.parseNumberWithFallback(
      companyPaiResult.answers.waterRecycled,
      0
    );
    if (waterConsumed === 0 && waterRecycled === 0) {
      return 0;
    }
    return waterRecycled / waterConsumed;
  },
  shareOfEnergyConsumption:
    ({ investeeMetricRef }: { investeeMetricRef: string }) =>
    (companyPaiResult: PaiResults[number]) => {
      // Non renewable
      const coalConsumption = HELPER_FUNCTIONS.parseNumberWithFallback(
        companyPaiResult.answers.coalConsumption,
        0
      );
      const fuelConsumptionOil = HELPER_FUNCTIONS.parseNumberWithFallback(
        companyPaiResult.answers.fuelConsumptionOil,
        0
      );
      const fuelConsumptionGas = HELPER_FUNCTIONS.parseNumberWithFallback(
        companyPaiResult.answers.fuelConsumptionGas,
        0
      );
      const fuelConsumptionOtherNon = HELPER_FUNCTIONS.parseNumberWithFallback(
        companyPaiResult.answers.fuelConsumptionOtherNon,
        0
      );
      const fuelConsumptionNuclear = HELPER_FUNCTIONS.parseNumberWithFallback(
        companyPaiResult.answers.fuelConsumptionNuclear,
        0
      );
      const fuelConsumptionElectricityNon = HELPER_FUNCTIONS.parseNumberWithFallback(
        companyPaiResult.answers.fuelConsumptionElectricityNon,
        0
      );

      const investeeMetric = HELPER_FUNCTIONS.parseNumberWithFallback(
        companyPaiResult.answers[investeeMetricRef],
        0
      );

      // Renewable
      const fuelConsumptionElectricityRenewable = HELPER_FUNCTIONS.parseNumberWithFallback(
        companyPaiResult.answers.fuelConsumptionElectricityRenewable,
        0
      );
      const fuelConsumptionSelf = HELPER_FUNCTIONS.parseNumberWithFallback(
        companyPaiResult.answers.fuelConsumptionSelf,
        0
      );
      const fuelConsumptionBio = HELPER_FUNCTIONS.parseNumberWithFallback(
        companyPaiResult.answers.fuelConsumptionBio,
        0
      );

      const totalEnergyConsumption =
        coalConsumption +
        fuelConsumptionElectricityNon +
        fuelConsumptionElectricityRenewable +
        fuelConsumptionSelf +
        fuelConsumptionBio +
        fuelConsumptionOil +
        fuelConsumptionOtherNon +
        fuelConsumptionGas +
        fuelConsumptionNuclear;

      // Weighted by ownership
      return {
        investeeMetricWithAlloc:
          investeeMetric * HELPER_FUNCTIONS.parseNumberWithFallback(companyPaiResult.allocation, 0),
        totalEnergyConsumption,
      };
    },
  nonRenewableEnergyConsumption: (companyPaiResult: PaiResults[number]) => {
    // non renewable energy
    const coalConsumption = HELPER_FUNCTIONS.parseNumberWithFallback(
      companyPaiResult.answers.coalConsumption,
      0
    );
    const fuelConsumptionOil = HELPER_FUNCTIONS.parseNumberWithFallback(
      companyPaiResult.answers.fuelConsumptionOil,
      0
    );
    const fuelConsumptionGas = HELPER_FUNCTIONS.parseNumberWithFallback(
      companyPaiResult.answers.fuelConsumptionGas,
      0
    );
    const fuelConsumptionOtherNon = HELPER_FUNCTIONS.parseNumberWithFallback(
      companyPaiResult.answers.fuelConsumptionOtherNon,
      0
    );
    const fuelConsumptionNuclear = HELPER_FUNCTIONS.parseNumberWithFallback(
      companyPaiResult.answers.fuelConsumptionNuclear,
      0
    );
    const fuelConsumptionElectricityNon = HELPER_FUNCTIONS.parseNumberWithFallback(
      companyPaiResult.answers.fuelConsumptionElectricityNon,
      0
    );

    // Total non-renewable energy consumption
    const nonRenewableConsumption =
      coalConsumption +
      fuelConsumptionOil +
      fuelConsumptionGas +
      fuelConsumptionOtherNon +
      fuelConsumptionNuclear +
      fuelConsumptionElectricityNon;

    // Renewable energy consumption
    const fuelConsumptionBio = HELPER_FUNCTIONS.parseNumberWithFallback(
      companyPaiResult.answers.fuelConsumptionBio,
      0
    );
    const fuelConsumptionElectricityRenewable = HELPER_FUNCTIONS.parseNumberWithFallback(
      companyPaiResult.answers.fuelConsumptionElectricityRenewable,
      0
    );
    const fuelConsumptionSelf = HELPER_FUNCTIONS.parseNumberWithFallback(
      companyPaiResult.answers.fuelConsumptionSelf,
      0
    );

    // Total renewable energy consumption
    const renewableConsumption =
      fuelConsumptionBio + fuelConsumptionElectricityRenewable + fuelConsumptionSelf;

    // Weighted by ownership
    return {
      nonRenewableConsumptionWithAlloc:
        nonRenewableConsumption *
        HELPER_FUNCTIONS.parseNumberWithFallback(companyPaiResult.allocation, 0),
      nonRenewableConsumption: nonRenewableConsumption,
      renewableConsumption: renewableConsumption,
    };
  },
  nonRenewableEnergyProduction: (companyPaiResult: PaiResults[number]) => {
    const nonRenewableEnergyProduction = HELPER_FUNCTIONS.parseNumberWithFallback(
      companyPaiResult.answers.nonRenewableEnergyProduction,
      0
    );
    const renewableEnergyProduction = HELPER_FUNCTIONS.parseNumberWithFallback(
      companyPaiResult.answers.renewableEnergyProduction,
      0
    );
    // Weighted by ownership
    return {
      nonRenewableEnergyProduction: nonRenewableEnergyProduction,
      nonRenewableEnergyProductionWithAlloc:
        nonRenewableEnergyProduction *
        HELPER_FUNCTIONS.parseNumberWithFallback(companyPaiResult.allocation, 0),
      renewableEnergyProduction: renewableEnergyProduction,
    };
  },
  ['biodiversity-sites']: (companyPaiResult: PaiResults[number]) => {
    const sites = HELPER_FUNCTIONS.parseNumberWithFallback(
      companyPaiResult.answers['biodiversity-sites'],
      0
    );
    return sites * HELPER_FUNCTIONS.getOwnership(companyPaiResult);
  },
  violationsOfUNGlobalCompactPrinciples: (companyPaiResult: PaiResults[number]) => {
    const oecd1 = companyPaiResult.answers['oecd-1'] === 'YES';
    const oecd2 = companyPaiResult.answers['oecd-2'] === 'YES';
    const oecd3 = companyPaiResult.answers['oecd-3'] === 'YES';

    if (oecd1 || oecd2 || oecd3) return true;
    return false;
  },
  lackOfProcesses: (companyPaiResult: PaiResults[number]) => {
    const commitmentOnSocial = companyPaiResult.answers.commitmentOnSocial === 'YES';
    const whistleblowerMechanism = companyPaiResult.answers.whistleblowerMechanism === 'YES';
    if (!commitmentOnSocial || !whistleblowerMechanism) return true;
    return false;
  },
  waterStressAreaBool_In: (companyPaiResult: PaiResults[number]) => {
    const waterStressIn = companyPaiResult.answers.waterStress_In === 'YES';
    const waterManagementBool = companyPaiResult.answers.waterManagementBool === 'YES';
    if (waterStressIn && !waterManagementBool === true) return true;
    return false;
  },
  ['esrs-waste-material-3']: (companyPaiResult: PaiResults[number]) => {
    const esrsWaste2 = Number.parseFloat(String(companyPaiResult.answers['esrs-waste2']));
    const esrsWastem1 = Number.parseFloat(String(companyPaiResult.answers['esrs-wastem1']));

    return esrsWaste2 / esrsWastem1;
  },
  ['esrs-waste-material-5']: (companyPaiResult: PaiResults[number]) => {
    const esrsWastem3 = Number.parseFloat(String(companyPaiResult.answers['esrs-wastem3'] ?? 0));
    const esrsWastem1 = Number.parseFloat(String(companyPaiResult.answers['esrs-wastem1'] ?? 1));

    return esrsWastem3 / esrsWastem1;
  },
  energyBreakdown: (energyMetric: string) => (companyPaiResult: PaiResults[number]) => {
    const energyMetricValue = HELPER_FUNCTIONS.parseNumberWithFallback(
      companyPaiResult.answers[energyMetric],
      0
    );
    const totalEnergyConsumption = HELPER_FUNCTIONS.parseNumberWithFallback(
      companyPaiResult.answers.totalEnergy,
      1
    );
    return energyMetricValue / (totalEnergyConsumption * 1000); // Convert total from GWh to MWh
  },
};

const INVESTOR_METRIC_CALCULATION_FUNCTIONS = {
  sumAndWeighByInvestments: (
    calculationFn: InvesteeMetricCalculationFn,
    results: PaiResults,
    cashAndSovereigns: CashSovereignsOptions
  ) => {
    const totalInvested = HELPER_FUNCTIONS.getTotalValueOfInvestments(results, cashAndSovereigns);

    const values = results.map(
      (res) =>
        Number(calculationFn(res) ?? 0) /
        (HELPER_FUNCTIONS.parseNumberWithFallback(totalInvested, 1) * 0.000001)
    );

    return HELPER_FUNCTIONS.addition(values);
  },
  formula3: (
    calculationFn: InvesteeMetricCalculationFn,
    results: PaiResults,
    cashAndSovereigns: CashSovereignsOptions
  ) => {
    const totalValueOfInvestments = HELPER_FUNCTIONS.getTotalValueOfInvestments(
      results,
      cashAndSovereigns
    );

    const values = results.map((res) => {
      const currResult = Number(calculationFn(res) ?? 0);
      return (
        currResult *
        (HELPER_FUNCTIONS.parseNumberWithFallback(res.allocation) / totalValueOfInvestments)
      );
    });
    return HELPER_FUNCTIONS.addition(values);
  },
  averageByTotalInvested: (calculationFn: InvesteeMetricCalculationFn, results: PaiResults) => {
    const values = HELPER_FUNCTIONS.weighByInfluence(calculationFn, results);

    return (
      (HELPER_FUNCTIONS.addition(values) /
        HELPER_FUNCTIONS.addition(results.map((res) => HELPER_FUNCTIONS.getOwnership(res)))) *
      100
    );
  },
  sumByInvestmentRatio: (
    calculationFn: InvesteeMetricCalculationFn,
    results: PaiResults,
    cashAndSovereigns: CashSovereignsOptions
  ) => {
    if (HELPER_FUNCTIONS.hasSomeEmptyAnswer(calculationFn, results)) {
      return PossibleErrors.hasEmptyAnswer;
    }
    const totalInvested = HELPER_FUNCTIONS.getTotalValueOfInvestments(results, cashAndSovereigns);
    const values = results.map((res) => {
      const currResult = Number(calculationFn(res) ?? 0);
      return (
        currResult * (HELPER_FUNCTIONS.parseNumberWithFallback(res.allocation) / totalInvested)
      );
    });
    return HELPER_FUNCTIONS.addition(values);
  },
  sumByInvestmentRatioPercentage: (
    calculationFn: InvesteeMetricCalculationFn,
    results: PaiResults,
    cashAndSovereigns: CashSovereignsOptions
  ) => {
    if (HELPER_FUNCTIONS.hasSomeEmptyAnswer(calculationFn, results)) {
      return PossibleErrors.hasEmptyAnswer;
    }
    const totalInvested = HELPER_FUNCTIONS.getTotalValueOfInvestments(results, cashAndSovereigns);
    const values = results.map((res) => {
      const currResult = Number(calculationFn(res) ?? 0);
      return (
        currResult * (HELPER_FUNCTIONS.parseNumberWithFallback(res.allocation) / totalInvested)
      );
    });
    return HELPER_FUNCTIONS.addition(values) * 100;
  },

  averageByTotalInvestedNotPercent: (
    calculationFn: InvesteeMetricCalculationFn,
    results: PaiResults
  ) => {
    const values = HELPER_FUNCTIONS.weighByInfluence(calculationFn, results);

    return (
      HELPER_FUNCTIONS.addition(values) /
      HELPER_FUNCTIONS.addition(results.map((res) => HELPER_FUNCTIONS.getOwnership(res)))
    );
  },

  carboonFootprint: (
    calculationFn: InvesteeMetricCalculationFn,
    results: PaiResults,
    cashAndSovereigns: CashSovereignsOptions
  ) => {
    const value = HELPER_FUNCTIONS.addition(results.map((res) => Number(calculationFn(res) ?? 0)));

    const totalValueOfInvestments = HELPER_FUNCTIONS.getTotalValueOfInvestments(
      results,
      cashAndSovereigns
    );
    return value / (totalValueOfInvestments * 0.000001);
  },
  shareOfEnergyConsumption: (
    calculationFn: InvesteeMetricCalculationFn,
    results: PaiResults,
    cashAndSovereigns: CashSovereignsOptions
  ) => {
    const totalValueOfInvestments = HELPER_FUNCTIONS.getTotalValueOfInvestments(
      results,
      cashAndSovereigns
    );
    const investeeRes = (res: PaiResults[number]) => {
      return calculationFn(res) as {
        investeeMetricWithAlloc: number;
        totalEnergyConsumption: number;
      };
    };

    const consumptionResult = HELPER_FUNCTIONS.addition(
      results.map(
        (res) =>
          investeeRes(res).investeeMetricWithAlloc /
          (HELPER_FUNCTIONS.nonZero(investeeRes(res).totalEnergyConsumption) *
            totalValueOfInvestments)
      )
    );

    return consumptionResult * 100;
  },
  nonRenewableEnergyConsumption: (
    calculationFn: InvesteeMetricCalculationFn,
    results: PaiResults,
    cashAndSovereigns: CashSovereignsOptions
  ) => {
    const totalValueOfInvestments = HELPER_FUNCTIONS.getTotalValueOfInvestments(
      results,
      cashAndSovereigns
    );
    const investeeRes = (res: PaiResults[number]) => {
      return calculationFn(res) as {
        nonRenewableConsumption: number;
        renewableConsumption: number;
        nonRenewableConsumptionWithAlloc: number;
      };
    };
    const consumptionResult = HELPER_FUNCTIONS.addition(
      results.map(
        (res) =>
          investeeRes(res).nonRenewableConsumptionWithAlloc /
          (HELPER_FUNCTIONS.nonZero(
            investeeRes(res).nonRenewableConsumption + investeeRes(res).renewableConsumption
          ) *
            totalValueOfInvestments)
      )
    );

    return consumptionResult * 100;
  },

  nonRenewableEnergyProduction: (
    calculationFn: InvesteeMetricCalculationFn,
    results: PaiResults,
    cashAndSovereigns: CashSovereignsOptions
  ) => {
    const totalValueOfInvestments = HELPER_FUNCTIONS.getTotalValueOfInvestments(
      results,
      cashAndSovereigns
    );
    const investeeRes = (res: PaiResults[number]) => {
      return calculationFn(res) as {
        nonRenewableEnergyProduction: number;
        renewableEnergyProduction: number;
        nonRenewableEnergyProductionWithAlloc: number;
      };
    };
    const productionResult = HELPER_FUNCTIONS.addition(
      results.map(
        (res) =>
          investeeRes(res).nonRenewableEnergyProductionWithAlloc /
          (HELPER_FUNCTIONS.nonZero(
            investeeRes(res).nonRenewableEnergyProduction +
              investeeRes(res).renewableEnergyProduction
          ) *
            totalValueOfInvestments)
      )
    );

    return productionResult * 100;
  },
  nAccidents_In: (
    calculationFn: InvesteeMetricCalculationFn,
    results: PaiResults,
    cashAndSovereigns: CashSovereignsOptions
  ) => {
    const totalValueOfInvestments = HELPER_FUNCTIONS.getTotalValueOfInvestments(
      results,
      cashAndSovereigns
    );
    return HELPER_FUNCTIONS.addition(
      results.map((res) => {
        const value = Number(calculationFn(res) ?? 0);
        return (
          value *
          (HELPER_FUNCTIONS.parseNumberWithFallback(res.allocation) / totalValueOfInvestments)
        );
      })
    );
  },
  // General
  sumMetric: (calculationFn: InvesteeMetricCalculationFn, results: PaiResults) => {
    const values = results.map((res) => Number(calculationFn(res) ?? 0));
    return HELPER_FUNCTIONS.addition(values);
  },
  sumMetricPerMillionEuros: (calculationFn: InvesteeMetricCalculationFn, results: PaiResults) => {
    const values = results.map((res) => Number(calculationFn(res) ?? 0));
    return HELPER_FUNCTIONS.addition(values) / 1000000;
  },
  averageTrueMetric: (
    calculationFn: InvesteeMetricCalculationFn,
    results: PaiResults,
    cashAndSovereigns: CashSovereignsOptions
  ) => {
    if (HELPER_FUNCTIONS.hasSomeEmptyAnswer(calculationFn, results)) {
      return PossibleErrors.hasEmptyAnswer;
    }
    const values = HELPER_FUNCTIONS.addition(
      results.map((v) => (calculationFn(v) === true ? 1 * v.allocation : 0))
    );
    const totalInvested = HELPER_FUNCTIONS.getTotalValueOfInvestments(results, cashAndSovereigns);
    return (values / totalInvested) * 100;
  },
  averageFalseMetric: (
    calculationFn: InvesteeMetricCalculationFn,
    results: PaiResults,
    cashAndSovereigns: CashSovereignsOptions
  ) => {
    if (HELPER_FUNCTIONS.hasSomeEmptyAnswer(calculationFn, results)) {
      return PossibleErrors.hasEmptyAnswer;
    }
    const values = HELPER_FUNCTIONS.addition(
      results.map((v) => (calculationFn(v) === true ? 0 : 1 * v.allocation))
    );
    const totalInvested = HELPER_FUNCTIONS.getTotalValueOfInvestments(results, cashAndSovereigns);
    return (values / totalInvested) * 100;
  },
  multiplyMetric:
    (multiplier: number, investorCalculationFn: InvestorMetricCalculationFn) =>
    (
      calculationFn: InvesteeMetricCalculationFn,
      results: PaiResults,
      cashAndSovereigns: CashSovereignsOptions
    ) => {
      const investorResult = investorCalculationFn(calculationFn, results, cashAndSovereigns);
      if (investorResult !== null && typeof investorResult === 'number') {
        return investorResult * multiplier;
      }
      return investorResult;
    },
};

const PAI_CALCULATIONS_PER_METRIC: {
  [key: string]: {
    investeeMetrics: string[];
    investorDataPointCalculation: InvestorMetricCalculationFn;
    investeeDataPointCalculation: InvesteeMetricCalculationFn;
  };
} = {
  scope1: {
    investeeMetrics: ['scope1'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('scope1'),
  },
  scope2: {
    investeeMetrics: ['scope2'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('scope2'),
  },
  scope3: {
    investeeMetrics: ['scope3'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('scope3'),
  },
  totalGhgEmissions: {
    investeeMetrics: ['scope1', 'scope2', 'scope3'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.totalGHG,
  },
  carbonFootprint: {
    investeeMetrics: ['scope1', 'scope2', 'scope3'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.carboonFootprint,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.carboonFootprint,
  },
  ghgIntensity: {
    investeeMetrics: ['scope1', 'scope2', 'scope3', 'revenue'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.ghgIntensity,
  },
  fossilFuelExposure: {
    investeeMetrics: ['fossilFuelExposure'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('fossilFuelExposure'),
  },
  // nonRenewableEnergy: {
  //   investeeMetrics: [
  //     'coalConsumption',
  //     'fuelConsumptionOil',
  //     'fuelConsumptionGas',
  //     'fuelConsumptionOtherNon',
  //     'fuelConsumptionNuclear',
  //     'fuelConsumptionElectricityNon',
  //     'fuelConsumptionBio',
  //     'fuelConsumptionElectricityRenewable',
  //     'fuelConsumptionSelf',
  //     'nonRenewableEnergyProduction',
  //     'renewableEnergyProduction',
  //   ],
  //   investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.nonRenewableEnergy,
  //   investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.nonRenewableEnergy,
  // },
  energynuclear_in_new: {
    investeeMetrics: [
      'coalConsumption',
      'fuelConsumptionElectricityNon',
      'fuelConsumptionElectricityRenewable',
      'fuelConsumptionSelf',
      'fuelConsumptionBio',
      'fuelConsumptionOil',
      'fuelConsumptionOtherNon',
      'fuelConsumptionGas',
      'fuelConsumptionNuclear',
    ],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.shareOfEnergyConsumption,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.shareOfEnergyConsumption({
      investeeMetricRef: 'fuelConsumptionNuclear',
    }),
  },
  energygas_in_new: {
    investeeMetrics: [
      'coalConsumption',
      'fuelConsumptionElectricityNon',
      'fuelConsumptionElectricityRenewable',
      'fuelConsumptionSelf',
      'fuelConsumptionBio',
      'fuelConsumptionOil',
      'fuelConsumptionOtherNon',
      'fuelConsumptionGas',
      'fuelConsumptionNuclear',
    ],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.shareOfEnergyConsumption,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.shareOfEnergyConsumption({
      investeeMetricRef: 'fuelConsumptionGas',
    }),
  },
  energycoal_in_new: {
    investeeMetrics: [
      'coalConsumption',
      'fuelConsumptionElectricityNon',
      'fuelConsumptionElectricityRenewable',
      'fuelConsumptionSelf',
      'fuelConsumptionBio',
      'fuelConsumptionOil',
      'fuelConsumptionOtherNon',
      'fuelConsumptionGas',
      'fuelConsumptionNuclear',
    ],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.shareOfEnergyConsumption,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.shareOfEnergyConsumption({
      investeeMetricRef: 'coalConsumption',
    }),
  },
  energyoil_in_new: {
    investeeMetrics: [
      'coalConsumption',
      'fuelConsumptionElectricityNon',
      'fuelConsumptionElectricityRenewable',
      'fuelConsumptionSelf',
      'fuelConsumptionBio',
      'fuelConsumptionOil',
      'fuelConsumptionOtherNon',
      'fuelConsumptionGas',
      'fuelConsumptionNuclear',
    ],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.shareOfEnergyConsumption,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.shareOfEnergyConsumption({
      investeeMetricRef: 'fuelConsumptionOil',
    }),
  },
  energyother_in_new: {
    investeeMetrics: [
      'coalConsumption',
      'fuelConsumptionElectricityNon',
      'fuelConsumptionElectricityRenewable',
      'fuelConsumptionSelf',
      'fuelConsumptionBio',
      'fuelConsumptionOil',
      'fuelConsumptionOtherNon',
      'fuelConsumptionGas',
      'fuelConsumptionNuclear',
    ],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.shareOfEnergyConsumption,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.shareOfEnergyConsumption({
      investeeMetricRef: 'fuelConsumptionOtherNon',
    }),
  },
  energypurchased_in_new: {
    investeeMetrics: [
      'coalConsumption',
      'fuelConsumptionElectricityNon',
      'fuelConsumptionElectricityRenewable',
      'fuelConsumptionSelf',
      'fuelConsumptionBio',
      'fuelConsumptionOil',
      'fuelConsumptionOtherNon',
      'fuelConsumptionGas',
      'fuelConsumptionNuclear',
    ],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.shareOfEnergyConsumption,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.shareOfEnergyConsumption({
      investeeMetricRef: 'fuelConsumptionElectricityNon',
    }),
  },
  nonRenewableEnergyConsumption: {
    investeeMetrics: [
      'coalConsumption',
      'fuelConsumptionOil',
      'fuelConsumptionGas',
      'fuelConsumptionOtherNon',
      'fuelConsumptionNuclear',
      'fuelConsumptionElectricityNon',
      'fuelConsumptionBio',
      'fuelConsumptionElectricityRenewable',
      'fuelConsumptionSelf',
    ],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.nonRenewableEnergyConsumption,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.nonRenewableEnergyConsumption,
  },
  nonRenewableEnergyProduction: {
    investeeMetrics: ['nonRenewableEnergyProduction', 'renewableEnergyProduction'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.nonRenewableEnergyProduction,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.nonRenewableEnergyProduction,
  },
  energyConsumptionIntensityHighImpact: {
    investeeMetrics: ['totalRevenueHighImpact', 'totalEnergyconsumptionHighImpact'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.formula3,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.energyConsumptionHighImpact,
  },
  'A-HICS': {
    investeeMetrics: ['A-revenue-HICS', 'A-consumption-HICS'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.formula3,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.energyConsumptionHICS({
      revenueMetric: 'A-revenue-HICS',
      consumptionMetric: 'A-consumption-HICS',
    }),
  },
  'B-HICS': {
    investeeMetrics: ['B-revenue-HICS', 'B-consumption-HICS'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.formula3,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.energyConsumptionHICS({
      revenueMetric: 'B-revenue-HICS',
      consumptionMetric: 'B-consumption-HICS',
    }),
  },
  'C-HICS': {
    investeeMetrics: ['C-revenue-HICS', 'C-consumption-HICS'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.formula3,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.energyConsumptionHICS({
      revenueMetric: 'C-revenue-HICS',
      consumptionMetric: 'C-consumption-HICS',
    }),
  },
  'D-HICS': {
    investeeMetrics: ['D-revenue-HICS', 'D-consumption-HICS'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.formula3,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.energyConsumptionHICS({
      revenueMetric: 'D-revenue-HICS',
      consumptionMetric: 'D-consumption-HICS',
    }),
  },
  'E-HICS': {
    investeeMetrics: ['E-revenue-HICS', 'E-consumption-HICS'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.formula3,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.energyConsumptionHICS({
      revenueMetric: 'E-revenue-HICS',
      consumptionMetric: 'E-consumption-HICS',
    }),
  },
  'F-HICS': {
    investeeMetrics: ['F-revenue-HICS', 'F-consumption-HICS'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.formula3,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.energyConsumptionHICS({
      revenueMetric: 'F-revenue-HICS',
      consumptionMetric: 'F-consumption-HICS',
    }),
  },
  'G-HICS': {
    investeeMetrics: ['G-revenue-HICS', 'G-consumption-HICS'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.formula3,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.energyConsumptionHICS({
      revenueMetric: 'G-revenue-HICS',
      consumptionMetric: 'G-consumption-HICS',
    }),
  },
  'H-HICS': {
    investeeMetrics: ['H-revenue-HICS', 'H-consumption-HICS'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.formula3,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.energyConsumptionHICS({
      revenueMetric: 'H-revenue-HICS',
      consumptionMetric: 'H-consumption-HICS',
    }),
  },
  'L-HICS': {
    investeeMetrics: ['L-revenue-HICS', 'L-consumption-HICS'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.formula3,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.energyConsumptionHICS({
      revenueMetric: 'L-revenue-HICS',
      consumptionMetric: 'L-consumption-HICS',
    }),
  },
  isImpactingBiodiversity: {
    investeeMetrics: ['isImpactingBiodiversity'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('isImpactingBiodiversity'),
  },
  emissionsToWater: {
    investeeMetrics: ['totalEmissionsToWater'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumAndWeighByInvestments,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('totalEmissionsToWater'),
  },
  emmissionOfInorganicPollutants: {
    investeeMetrics: ['emmissionOfInorganicPollutants'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumAndWeighByInvestments,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence(
      'emmissionOfInorganicPollutants'
    ),
  },
  emmissionsOfAirPollutants: {
    investeeMetrics: ['emmissionsOfAirPollutants'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumAndWeighByInvestments,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence(
      'emmissionsOfAirPollutants'
    ),
  },
  hazardousWaste: {
    investeeMetrics: ['hazardousWaste'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumAndWeighByInvestments,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('hazardousWaste'),
  },
  unadjustedPayGap: {
    investeeMetrics: ['unadjustedPayGap'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('unadjustedPayGap'),
  },
  boardGenderDiversity: {
    investeeMetrics: ['boardGenderDiversity'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('boardGenderDiversity'),
  },
  controversialWeapons: {
    investeeMetrics: ['controversialWeapons'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('controversialWeapons'),
  },
  carbonEmissionReductionInitiatives: {
    investeeMetrics: ['carbonEmissionReductionInitiatives'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric(
      'carbonEmissionReductionInitiatives'
    ),
  },
  insufficientWhistleblowerProtection: {
    investeeMetrics: ['insufficientWhistleblowerProtection'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric(
      'insufficientWhistleblowerProtection'
    ),
  },
  violationsOfUNGlobalCompactPrinciples: {
    investeeMetrics: ['oecd-1', 'oecd-2', 'oecd-3'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.violationsOfUNGlobalCompactPrinciples,
  },
  lackOfProcesses: {
    investeeMetrics: ['commitmentOnSocial', 'whistleblowerMechanism'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.lackOfProcesses,
  },
  waterConsumed: {
    investeeMetrics: ['waterConsumed', 'revenue'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.formula3,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.waterConsumed,
  },
  tonnesOzoneDepleting: {
    investeeMetrics: ['tonnesOzoneDepleting_In'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumAndWeighByInvestments,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('tonnesOzoneDepleting_In'),
  },
  waterRecycled: {
    investeeMetrics: ['waterRecycled', 'waterConsumed'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.waterRecycled,
  },
  waterManagementBool_In: {
    investeeMetrics: ['waterManagementBool'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('waterManagementBool'),
  },
  waterStressAreaBool_In: {
    investeeMetrics: ['waterStress_In', 'waterManagementBool'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.waterStressAreaBool_In,
  },
  producingChemicalsBool_In: {
    investeeMetrics: ['producingChemicalsBool'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('producingChemicalsBool'),
  },
  soilProblems_In: {
    investeeMetrics: ['soilProblems'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('soilProblems'),
  },
  sustainableLandPolicyBool_In: {
    investeeMetrics: ['sustainableLandPolicy'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('sustainableLandPolicy'),
  },
  sustainableOceanPolicyBool_in: {
    investeeMetrics: ['sustainableOceanPolicyBool'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric(
      'sustainableOceanPolicyBool'
    ),
  },
  tonnesNonRecycledWaste_In: {
    investeeMetrics: ['tonnesNonRecycledWaste'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumAndWeighByInvestments,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('tonnesNonRecycledWaste'),
  },
  naturalSpeciesAndProtectedAreas: {
    investeeMetrics: ['naturalSpeciesAndProtectedAreas'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric(
      'naturalSpeciesAndProtectedAreas'
    ),
  },
  biodiversityPolicyBool_In: {
    investeeMetrics: ['biodiversityPolicyBool'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('biodiversityPolicyBool'),
  },
  deforestation: {
    investeeMetrics: ['deforestation'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('deforestation'),
  },
  accidentPolicyBool_In: {
    investeeMetrics: ['accidentPolicyBool'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('accidentPolicyBool'),
  },
  nAccidents_In: {
    investeeMetrics: ['nAccidents', 'totalEmployees', 'revenue'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.nAccidents_In,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.nAccidents_In,
  },
  nWorkdayslost_In: {
    investeeMetrics: ['nWorkdaysLost'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('nWorkdaysLost'),
  },
  SupplierCodeBool_In: {
    investeeMetrics: ['SupplierCodeBool'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('SupplierCodeBool'),
  },
  grievanceMechanismBool_In: {
    investeeMetrics: ['grievanceMechanismBool'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('grievanceMechanismBool'),
  },
  nDiscriminationIncidents_In: {
    investeeMetrics: ['nDiscriminationIncidents'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric(
      'nDiscriminationIncidents'
    ),
  },
  nDiscriminationSanctions_In: {
    investeeMetrics: ['nDiscriminationS'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('nDiscriminationS'),
  },
  ratioCompensation: {
    investeeMetrics: ['ratioCompensation'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('ratioCompensation'),
  },
  riskChildLabourBool_In: {
    investeeMetrics: ['riskChildLabourBool'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('riskChildLabourBool'),
  },
  forcedLabourBool_In: {
    investeeMetrics: ['forcedLabourBool'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('forcedLabourBool'),
  },
  nHumanRightsIncidents_In: {
    investeeMetrics: ['nHumanRightsIncidents'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('nHumanRightsIncidents'),
  },
  lackOfHumanRightsPolicy: {
    investeeMetrics: ['lackOfHumanRightsPolicy'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('lackOfHumanRightsPolicy'),
  },
  DDPolicyBool_In: {
    investeeMetrics: ['DDPolicyBool'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('DDPolicyBool'),
  },
  traffickingPolicyBool_In: {
    investeeMetrics: ['traffickingPolicyBool'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('traffickingPolicyBool'),
  },
  anticorruptionPolicyBool_In: {
    investeeMetrics: ['anticorruptionBool'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('anticorruptionBool'),
  },
  actionsAnticorruption_In: {
    investeeMetrics: ['actionsAnticorruption'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('actionsAnticorruption'),
  },
  nConvictionsCorruption_In: {
    investeeMetrics: ['nConvictionsCorruption'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('nConvictionsCorruption'),
  },
  finescorruption_in: {
    investeeMetrics: ['finescorruption'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('finescorruption'),
  },
  ['ghg-removals-amount']: {
    investeeMetrics: ['ghg-removals-amount'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('ghg-removals-amount'),
  },
  ['ghg-carbon-purchase']: {
    investeeMetrics: ['ghg-carbon-purchase'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('ghg-carbon-purchase'),
  },
  ['esrs-waste']: {
    investeeMetrics: ['esrs-waste'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste'),
  },
  ['esrs-total-waste-1']: {
    investeeMetrics: ['esrs-waste-1'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste-1'),
  },
  ['esrs-total-waste-2']: {
    investeeMetrics: ['esrs-waste-2'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste-2'),
  },
  ['esrs-total-waste-3']: {
    investeeMetrics: ['esrs-waste-3'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste-3'),
  },
  ['esrs-total-waste-4']: {
    investeeMetrics: ['esrs-waste-4'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste-4'),
  },
  ['esrs-total-waste-5']: {
    investeeMetrics: ['esrs-waste-5'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste-5'),
  },
  ['esrs-total-waste-6']: {
    investeeMetrics: ['esrs-waste-6'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste-6'),
  },
  ['esrs-total-waste-7']: {
    investeeMetrics: ['esrs-waste-7'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste-7'),
  },
  ['esrs-total-waste-8']: {
    investeeMetrics: ['esrs-waste-8'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste-8'),
  },
  ['esrs-total-waste-9']: {
    investeeMetrics: ['esrs-waste-9'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste-9'),
  },
  ['esrs-total-waste-10']: {
    investeeMetrics: ['esrs-waste-10'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste-10'),
  },
  ['esrs-total-waste-11']: {
    investeeMetrics: ['esrs-waste-11'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste-11'),
  },
  ['esrs-waste-12']: {
    investeeMetrics: ['esrs-waste-12'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste-12'),
  },
  ['esrs-total-waste-13']: {
    investeeMetrics: ['esrs-waste-13'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste-13'),
  },
  ['esrs-total-waste-14']: {
    investeeMetrics: ['esrs-waste-13', 'esrs-waste'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.divisionMetric(
      'esrs-waste-13',
      'esrs-waste'
    ),
  },
  ['esrs-waste-material']: {
    investeeMetrics: ['esrs-wastem1'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-wastem1'),
  },
  ['esrs-waste-material-2']: {
    investeeMetrics: ['esrs-waste2'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste2'),
  },
  ['esrs-waste-material-3']: {
    investeeMetrics: ['esrs-waste2', 'esrs-wastem1'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS['esrs-waste-material-3'],
  },
  ['esrs-waste-material-4']: {
    investeeMetrics: ['esrs-wastem3'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-wastem3'),
  },
  ['esrs-waste-material-5']: {
    investeeMetrics: ['esrs-wastem3', 'esrs-wastem1'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS['esrs-waste-material-5'],
  },
  ['esrs-employee-turnover']: {
    investeeMetrics: ['esrs-employee-turnover', 'esrs-average-employees'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.divisionMetric(
      'esrs-employee-turnover',
      'esrs-average-employees'
    ),
  },
  ['custom-total-hires']: {
    investeeMetrics: ['custom-total-hires'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('custom-total-hires'),
  },
  ['custom-relative-employees']: {
    investeeMetrics: ['custom-relative-employees', 'custom-total-hires'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.divisionMetric(
      'custom-total-hires',
      'custom-relative-employees'
    ),
  },
  ['esrs-risk-policy']: {
    investeeMetrics: ['esrs-risk-policy'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('esrs-risk-policy'),
  },
  ['cross-cutting1']: {
    investeeMetrics: ['cross-cutting1'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageByTotalInvestedNotPercent,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('cross-cutting1'),
  },
  ['cross-cutting2']: {
    investeeMetrics: ['cross-cutting2'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('cross-cutting2'),
  },
  ['company-risk-1']: {
    investeeMetrics: ['company-risk-1'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('company-risk-1'),
  },
  ['company-risk-2']: {
    investeeMetrics: ['company-risk-2'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('company-risk-2'),
  },
  ['company-risk-3']: {
    investeeMetrics: ['company-risk-3'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('company-risk-3'),
  },
  ['company-risk-4']: {
    investeeMetrics: ['company-risk-4'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('company-risk-4'),
  },
  ['company-risk-5']: {
    investeeMetrics: ['company-risk-5'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('company-risk-5'),
  },
  ['company-risk-6']: {
    investeeMetrics: ['company-risk-6'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('company-risk-6'),
  },
  ['company-risk-7']: {
    investeeMetrics: ['company-risk-7'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('company-risk-7'),
  },
  ['company-risk-8']: {
    investeeMetrics: ['company-risk-8'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('company-risk-8'),
  },
  ['company-risk-9']: {
    investeeMetrics: ['company-risk-9'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('company-risk-9'),
  },
  ['biodiversity-sites']: {
    investeeMetrics: ['biodiversity-sites'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('biodiversity-sites'),
  },
  ['biodiversity-area']: {
    investeeMetrics: ['biodiversity-area'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('biodiversity-area'),
  },
  ['esrs-water']: {
    investeeMetrics: ['esrs-water', 'revenue'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.formula3,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.divisionMetricPerMillionEuros('esrs-water', 'revenue'),
  },
  ['custom-goverment-ownership']: {
    investeeMetrics: ['custom-goverment-ownership'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric(
      'custom-goverment-ownership'
    ),
  },
  contributionmitigation: {
    investeeMetrics: ['custom-cross-cutting1a'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('custom-cross-cutting1a'),
  },
  contributionadaptation: {
    investeeMetrics: ['custom-cross-cutting1b'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('custom-cross-cutting1b'),
  },
  contributionmarine: {
    investeeMetrics: ['custom-cross-cutting1c'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('custom-cross-cutting1c'),
  },
  contributioncirculareconomy: {
    investeeMetrics: ['custom-cross-cutting1d'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('custom-cross-cutting1d'),
  },
  contributionpollution: {
    investeeMetrics: ['custom-cross-cutting1e'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('custom-cross-cutting1e'),
  },
  contributionbiodiversity: {
    investeeMetrics: ['custom-cross-cutting1f'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('custom-cross-cutting1f'),
  },
  harmmitigation: {
    investeeMetrics: ['custom-cross-cutting2a'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('custom-cross-cutting2a'),
  },
  harmadaptation: {
    investeeMetrics: ['custom-cross-cutting2b'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('custom-cross-cutting2b'),
  },
  harmmarine: {
    investeeMetrics: ['custom-cross-cutting2c'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('custom-cross-cutting2c'),
  },
  harmcircular: {
    investeeMetrics: ['custom-cross-cutting2d'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('custom-cross-cutting2d'),
  },
  harmpollution: {
    investeeMetrics: ['custom-cross-cutting2e'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('custom-cross-cutting2e'),
  },
  harmbiodiversity: {
    investeeMetrics: ['custom-cross-cutting2f'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('custom-cross-cutting2f'),
  },
  energycoal_in: {
    investeeMetrics: ['coalConsumption', 'totalEnergy'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.energyBreakdown('coalConsumption'),
  },
  energyoil_in: {
    investeeMetrics: ['fuelConsumptionOil', 'totalEnergy'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.energyBreakdown('fuelConsumptionOil'),
  },
  energynuclear_in: {
    investeeMetrics: ['fuelConsumptionNuclear', 'totalEnergy'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.energyBreakdown('fuelConsumptionNuclear'),
  },
  energypurchased_in: {
    investeeMetrics: ['fuelConsumptionElectricityNon', 'totalEnergy'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.energyBreakdown(
      'fuelConsumptionElectricityNon'
    ),
  },
  energygas_in: {
    investeeMetrics: ['fuelConsumptionGas', 'totalEnergy'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.energyBreakdown('fuelConsumptionGas'),
  },
  energyother_in: {
    investeeMetrics: ['fuelConsumptionOtherNon', 'totalEnergy'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.energyBreakdown('fuelConsumptionOtherNon'),
  },
  totalenergy_in: {
    investeeMetrics: ['totalEnergy'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('totalEnergy'),
  },
  ['custom-nationality-representation']: {
    investeeMetrics: ['custom-nationality-representation'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric(
      'custom-nationality-representation'
    ),
  },
  ['custom-gender-diversity']: {
    investeeMetrics: ['custom-gender-diversity'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('custom-gender-diversity'),
  },
  ['custom-biofouling']: {
    investeeMetrics: ['custom-biofouling'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('custom-biofouling'),
  },
  ['custom-GHG-emissions-reduction']: {
    investeeMetrics: ['custom-GHG-emissions-reductions'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence(
      'custom-GHG-emissions-reductions'
    ),
  },
  ['custom-number-fte']: {
    investeeMetrics: ['custom-number-fte'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.formula3,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('custom-number-fte'),
  },
  ['custom-female-fte']: {
    investeeMetrics: ['custom-female-fte'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.formula3,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('custom-female-fte'),
  },
  ['custom-number-management']: {
    investeeMetrics: ['custom-number-management'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.formula3,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric(
      'custom-number-management'
    ),
  },
  ['custom-female-management']: {
    investeeMetrics: ['custom-female-management'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.formula3,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric(
      'custom-female-management'
    ),
  },
  ['custom-number-board-members']: {
    investeeMetrics: ['custom-number-board-members'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.formula3,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric(
      'custom-number-board-members'
    ),
  },
  ['custom-female-board-members']: {
    investeeMetrics: ['custom-female-board-members'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.formula3,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric(
      'custom-female-board-members'
    ),
  },
  ['custom-sustainability-agenda']: {
    investeeMetrics: ['custom-sustainability-agenda'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric(
      'custom-sustainability-agenda'
    ),
  },
  ['custom-sustainability-agenda-item']: {
    investeeMetrics: ['custom-sustainability-agenda-item'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric(
      'custom-sustainability-agenda-item'
    ),
  },
  // Policy type investor metrics
  ['employeeInsurancesInvestor']: {
    investeeMetrics: ['employeeInsurancesInvestee'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric(
      'employeeInsurancesInvestee'
    ),
  },
  ['ESGResponsiblePersonInvestor']: {
    investeeMetrics: ['ESGResponsiblePersonInvestee'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric(
      'ESGResponsiblePersonInvestee'
    ),
  },
  ['antiCorruptionInvestor']: {
    investeeMetrics: ['antiCorruptionInvestee'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('antiCorruptionInvestee'),
  },
  ['liabilityInsuranceInvestor']: {
    investeeMetrics: ['liabilityInsuranceInvestee'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric(
      'liabilityInsuranceInvestee'
    ),
  },
  ['overall-sust-policy']: {
    investeeMetrics: ['overall-sust-policy'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('overall-sust-policy'),
  },
  ['env-policy']: {
    investeeMetrics: ['env-policy'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('env-policy'),
  },
  ['anti-dis-equal-policy']: {
    investeeMetrics: ['anti-dis-equal-policy'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('anti-dis-equal-policy'),
  },
  ['diversity-inclusion-policy']: {
    investeeMetrics: ['diversity-inclusion-policy'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric(
      'diversity-inclusion-policy'
    ),
  },
  ['survey-employee']: {
    investeeMetrics: ['survey-employee'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('survey-employee'),
  },
  ['health-safety-policy']: {
    investeeMetrics: ['health-safety-policy'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('health-safety-policy'),
  },
  ['cyber-security-program']: {
    investeeMetrics: ['cyber-security-program'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('cyber-security-program'),
  },
  ['privacy-policy']: {
    investeeMetrics: ['privacy-policy'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('privacy-policy'),
  },
  ['code-conduct']: {
    investeeMetrics: ['code-conduct'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('code-conduct'),
  },
  ['CarbonNeutralPledge']: {
    investeeMetrics: ['CarbonNeutralPledge'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('CarbonNeutralPledge'),
  },
  ['KYCScreeningVendors']: {
    investeeMetrics: ['KYCScreeningVendors'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('KYCScreeningVendors'),
  },
  ['dataCyberIncidents']: {
    investeeMetrics: ['dataCyberIncidents'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('dataCyberIncidents'),
  },
  ['DEIpolicies']: {
    investeeMetrics: ['DEIpolicies'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('DEIpolicies'),
  },
  ['totalIncidentsESGInvestor']: {
    investeeMetrics: ['totalIncidentsESGInvestee'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric(
      'totalIncidentsESGInvestee'
    ),
  },
  // Diversity investor metrics
  employeeDiversityFemale: {
    investeeMetrics: ['employeeDiversityTotal', 'employeeDiversityFemale'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.divisionMetric(
      'employeeDiversityFemale',
      'employeeDiversityTotal'
    ),
  },
  employeeDiversityRacialized: {
    investeeMetrics: ['employeeDiversityTotal', 'employeeDiversityRacialized'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.divisionMetric(
      'employeeDiversityRacialized',
      'employeeDiversityTotal'
    ),
  },
  employeeDiversityIndigenous: {
    investeeMetrics: ['employeeDiversityTotal', 'employeeDiversityIndigenous'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.divisionMetric(
      'employeeDiversityIndigenous',
      'employeeDiversityTotal'
    ),
  },
  leadershipTeamDiversityFemale: {
    investeeMetrics: ['leadershipTeamDiversityTotal', 'leadershipTeamDiversityFemale'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.divisionMetric(
      'leadershipTeamDiversityFemale',
      'leadershipTeamDiversityTotal'
    ),
  },
  leadershipTeamDiversityRacialized: {
    investeeMetrics: ['leadershipTeamDiversityTotal', 'leadershipTeamDiversityRacialized'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.divisionMetric(
      'leadershipTeamDiversityRacialized',
      'leadershipTeamDiversityTotal'
    ),
  },
  leadershipTeamDiversityIndigenous: {
    investeeMetrics: ['leadershipTeamDiversityTotal', 'leadershipTeamDiversityIndigenous'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.divisionMetric(
      'leadershipTeamDiversityIndigenous',
      'leadershipTeamDiversityTotal'
    ),
  },
  BoardDiversityIndependenceFemale: {
    investeeMetrics: ['BoardDiversityIndependenceTotal', 'BoardDiversityIndependenceFemale'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.divisionMetric(
      'BoardDiversityIndependenceFemale',
      'BoardDiversityIndependenceTotal'
    ),
  },
  BoardDiversityIndependenceRacialized: {
    investeeMetrics: ['BoardDiversityIndependenceTotal', 'BoardDiversityIndependenceRacialized'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.divisionMetric(
      'BoardDiversityIndependenceRacialized',
      'BoardDiversityIndependenceTotal'
    ),
  },
  BoardDiversityIndependenceIndigenous: {
    investeeMetrics: ['BoardDiversityIndependenceTotal', 'BoardDiversityIndependenceIndigenous'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.divisionMetric(
      'BoardDiversityIndependenceIndigenous',
      'BoardDiversityIndependenceTotal'
    ),
  },
  BoardDiversityIndependenceIndependent: {
    investeeMetrics: ['BoardDiversityIndependenceTotal', 'BoardDiversityIndependenceIndependent'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.divisionMetric(
      'BoardDiversityIndependenceIndependent',
      'BoardDiversityIndependenceTotal'
    ),
  },
  ['management-percentage-female']: {
    investeeMetrics: ['custom-number-management', 'custom-female-management'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.divisionMetric(
      'custom-female-management',
      'custom-number-management'
    ),
  },
  ['management-percentage-nonbinary']: {
    investeeMetrics: ['custom-number-management', 'management-nonbinary'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.divisionMetric(
      'management-nonbinary',
      'custom-number-management'
    ),
  },
  ['female-board-percentage']: {
    investeeMetrics: ['custom-number-board-members', 'custom-female-board-members'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.divisionMetric(
      'custom-female-board-members',
      'custom-number-board-members'
    ),
  },
  ['nonbinary-percentage-board']: {
    investeeMetrics: ['custom-number-board-members', 'board-nonbinary-custom'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.divisionMetric(
      'board-nonbinary-custom',
      'custom-number-board-members'
    ),
  },
  // Custom metrics
  ['injuries-number']: {
    investeeMetrics: ['injuries-number'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('injuries-number'),
  },
  ['annual-perc-turnover']: {
    investeeMetrics: ['annual-perc-turnover'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('annual-perc-turnover'),
  },
  // Breakdowns
  energyConsumptionBreakdownRenewableShare: {
    investeeMetrics: [
      'fuelConsumptionElectricityRenewable',
      'fuelConsumptionSelf',
      'fuelConsumptionBio',
      'fuelConsumptionOil',
      'fuelConsumptionGas',
      'fuelConsumptionOtherNon',
      'fuelConsumptionNuclear',
      'fuelConsumptionElectricityNon',
      'coalConsumption',
    ],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.divisionMetricMany(
      ['fuelConsumptionElectricityRenewable', 'fuelConsumptionSelf', 'fuelConsumptionBio'],
      [
        'fuelConsumptionElectricityRenewable',
        'fuelConsumptionSelf',
        'fuelConsumptionBio',
        'fuelConsumptionOil',
        'fuelConsumptionGas',
        'fuelConsumptionOtherNon',
        'fuelConsumptionNuclear',
        'fuelConsumptionElectricityNon',
        'coalConsumption',
      ]
    ),
  },
  energyConsumptionBreakdownNonrenewableShare: {
    investeeMetrics: [
      'fuelConsumptionElectricityRenewable',
      'fuelConsumptionSelf',
      'fuelConsumptionBio',
      'coalConsumption',
      'fuelConsumptionOil',
      'fuelConsumptionGas',
      'fuelConsumptionOtherNon',
      'fuelConsumptionNuclear',
      'fuelConsumptionElectricityNon',
    ],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.divisionMetricMany(
      [
        'fuelConsumptionOtherNon',
        'fuelConsumptionElectricityNon',
        'fuelConsumptionNuclear',
        'fuelConsumptionGas',
        'fuelConsumptionOil',
        'coalConsumption',
      ],
      [
        'fuelConsumptionElectricityRenewable',
        'fuelConsumptionSelf',
        'fuelConsumptionBio',
        'coalConsumption',
        'fuelConsumptionOil',
        'fuelConsumptionGas',
        'fuelConsumptionOtherNon',
        'fuelConsumptionNuclear',
        'fuelConsumptionElectricityNon',
      ]
    ),
  },
  energyConsumptionBreakdownRenewable: {
    investeeMetrics: [
      'fuelConsumptionElectricityRenewable',
      'fuelConsumptionSelf',
      'fuelConsumptionBio',
    ],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.multiplyMetric(
      0.001,
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio
    ),
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.sumMetric([
      'fuelConsumptionElectricityRenewable',
      'fuelConsumptionSelf',
      'fuelConsumptionBio',
    ]),
  },
  energyConsumptionBreakdownNonrenewable: {
    investeeMetrics: [
      'coalConsumption',
      'fuelConsumptionOil',
      'fuelConsumptionGas',
      'fuelConsumptionOtherNon',
      'fuelConsumptionElectricityNon',
      'fuelConsumptionNuclear',
    ],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.multiplyMetric(
      0.001,
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio
    ),
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.sumMetric([
      'coalConsumption',
      'fuelConsumptionOil',
      'fuelConsumptionGas',
      'fuelConsumptionOtherNon',
      'fuelConsumptionElectricityNon',
      'fuelConsumptionNuclear',
    ]),
  },
  energyConsumptionBreakdownTotal: {
    investeeMetrics: [
      'fuelConsumptionElectricityNon',
      'fuelConsumptionElectricityRenewable',
      'fuelConsumptionSelf',
      'fuelConsumptionNuclear',
      'coalConsumption',
      'fuelConsumptionOil',
      'fuelConsumptionGas',
      'fuelConsumptionOtherNon',
      'fuelConsumptionBio',
    ],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.multiplyMetric(
      0.001,
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio
    ),
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.sumMetric([
      'fuelConsumptionElectricityNon',
      'fuelConsumptionElectricityRenewable',
      'fuelConsumptionSelf',
      'fuelConsumptionNuclear',
      'coalConsumption',
      'fuelConsumptionOil',
      'fuelConsumptionGas',
      'fuelConsumptionOtherNon',
      'fuelConsumptionBio',
    ]),
  },
  shareLessAdequateWageInvestor: {
    investeeMetrics: ['lessThanAdequateWageInvestee'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric(
      'lessThanAdequateWageInvestee'
    ),
  },
  exposureTobaccoInvestor: {
    investeeMetrics: ['exposureTobaccoInvestee'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('exposureTobaccoInvestee'),
  },
};

export interface MetricResult {
  result: string | number;
  hasUnits: boolean;
}

export const noNAN = (v: any, hasUnits = true): MetricResult => {
  if (v === PossibleErrors.marketValueMissing)
    return { result: 'Market value missing for some companies', hasUnits: false };
  if (v === PossibleErrors.hasEmptyAnswer)
    return { result: 'Answers missing for some companies', hasUnits: false };
  if (v === undefined || v === null || v === '') return { result: 'No data', hasUnits: false };
  if (isNaN(v)) return { result: 'In progress', hasUnits: false };
  return { result: formatNumDecimals(v), hasUnits };
};

export const calculateMetricResults = (
  investorMetric: InvestorMetric,
  investeeResults: PaiResults,
  cashAndSovereigns: CashSovereignsOptions
): MetricResult => {
  const metricRef = investorMetric.reference;
  const metricCalculation = PAI_CALCULATIONS_PER_METRIC?.[metricRef];
  if (!investorMetric?.isAggregatable) return { result: 'Not aggregated', hasUnits: false };
  if (!metricCalculation) return { result: 'N/A', hasUnits: false };

  const metricCalculationResult = metricCalculation.investorDataPointCalculation(
    metricCalculation.investeeDataPointCalculation,
    investeeResults,
    cashAndSovereigns
  );
  if (metricCalculationResult === Infinity) {
    return { result: Infinity, hasUnits: false };
  }
  const result = noNAN(metricCalculationResult, true);
  return { result: result.result, hasUnits: result.hasUnits };
};

export const getMetricProgress = (
  investorMetric: InvestorMetric,
  investeeResults: PaiResults,
  companyCount: number
) => {
  const total = companyCount;
  const metricCalculation = PAI_CALCULATIONS_PER_METRIC?.[investorMetric.reference];
  let requiredMetrics = investorMetric.investeeMetrics.map((metric) => metric.metric.reference);
  if (investorMetric.isAggregatable && metricCalculation) {
    requiredMetrics = metricCalculation?.investeeMetrics ?? [];
  }
  const answered = investeeResults.filter((res) =>
    requiredMetrics.every((metric) => !!res.answers[metric] && res.answers[metric] !== null)
  ).length;

  return (answered / total) * 100;
};
