import { Box, Center, HStack, Skeleton, VStack } from '@chakra-ui/react';
import { Alert, Button, Checkbox, EmptyState, IconButton, Infobox } from 'Atoms';
import { useCurrentCompany } from 'utils/hooks';
import { portfolioOwnerContext, useCashAndSovereigns } from 'containers/Portfolios';
import usePortfolios from 'containers/Portfolios/Portfolios.hooks';
import { uniq } from 'lodash';
import { useGetPaiPortfolioReportQuery, PaiPortfolioReport, QuarterEnum_Enum_ } from 'models';
import React, { useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PaiIndicatorsIllustration, Typography } from 'Tokens';
import { IndicatorsResultsTable } from './IndicatorsResultsTable';
import {
  getResultsFilter,
  getReportFilter,
  usePaiReportAnswers,
  ALL_FRAMEWORKS,
} from './PortfolioPai.hooks';
import { useTranslation } from 'utils/translation';
import { CelsiaSpinner, SearchInput, Select } from 'Molecules';
import { components, ValueContainerProps } from 'react-select';
import { EditIcon, RefreshIcon, RemoveIcon } from 'Tokens/Icons/Function';
import { PaiExport } from './PaiExport';
import { formatDateToDDMMYYYY, formatDate, splitQuarterYear } from 'utils/date';
import { useCurrentReportPeriod } from 'Features/PortfolioView';
import { sendHotjarEvent } from 'utils/hotjar';
import { CashSovereignSelectorMenu } from 'Features/PortfolioView/CashSovereignSelectorMenu';
import { ARTICLE_REPORTING_CASH_SOVEREIGNS_KPI } from 'Features/KnowledgeBase/KnowledgeBaseArticles';
import { useKnowledgeBase } from 'Features';
import { HelpIcon } from 'Tokens/Icons/Status';

const Option = (props: any) => {
  return (
    <components.Option {...props}>
      <HStack paddingX="4px">
        <Checkbox isChecked={props.isSelected} onChange={() => null} />
        <Typography variant="bodyStrong" color="text.muted">
          {props.label}
        </Typography>
      </HStack>
    </components.Option>
  );
};

const ValueContainer = ({
  children,
  ...props
}: ValueContainerProps<{ value: string; label: string }>) => {
  const { getValue, selectProps } = props;
  const [values, input] = children as any;
  const inputValue = selectProps.inputValue.length > 0 ? true : false;
  const options = getValue();
  const label: string[] = options?.map((item: { value: string; label: string }, index: number) =>
    index === options.length ? item.label : ' ' + item.label
  );

  return (
    <components.ValueContainer {...props}>
      <HStack spacing="0px">
        {options.length ? (
          <Typography noOfLines={1}>{label?.toString()}</Typography>
        ) : inputValue ? (
          <></>
        ) : (
          <Typography variant="body" color="text.default">
            All companies
          </Typography>
        )}
        {values}
        {input}
      </HStack>
    </components.ValueContainer>
  );
};

const FilterBar = React.forwardRef(
  (
    {
      searchValue,
      setSearchValue,
      selectedCompanies,
      setSelectedCompanies,
      selectedFramework,
      setSelectedFramework,
      tables,
      reportingYear,
      includeSovereigns,
      setIncludeSovereigns,
      includeCash,
      setIncludeCash,
      cash,
      sovereigns,
    }: {
      searchValue: string;
      setSearchValue: (value: string) => void;
      selectedCompanies: { value: string; label: string }[];
      setSelectedCompanies: (value: { value: string; label: string }[]) => void;
      selectedFramework: string;
      setSelectedFramework: (value: string) => void;
      tables: string[];
      reportingYear: string;
      includeSovereigns: boolean;
      setIncludeSovereigns: (value: boolean) => void;
      includeCash: boolean;
      setIncludeCash: (value: boolean) => void;
      cash: number;
      sovereigns: number;
    },
    _ref
  ) => {
    const { portfolioId } = useParams();
    const { getPortfolio } = usePortfolios();
    const portfolio = getPortfolio(portfolioId ?? '', reportingYear);
    const portfolioCompanies = portfolio?.portfolioCompanies ?? [];
    const selectRef = useRef<any>(null);
    const { reportPeriod } = useCurrentReportPeriod();
    const { onOpen: openArticle } = useKnowledgeBase();

    return (
      <VStack width="100%" spacing="16px">
        <HStack alignSelf="start" width="100%">
          <Box flex={0.75}>
            <SearchInput
              search={searchValue}
              setSearch={setSearchValue}
              placeholder="Filter indicators"
              w="300px"
            />
          </Box>
          <Box flex={0.75}>
            <Select<{ value: string; label: string }>
              value={
                selectedFramework
                  ? { value: selectedFramework, label: selectedFramework }
                  : { value: ALL_FRAMEWORKS, label: 'All frameworks' }
              }
              onChange={(value: any) =>
                setSelectedFramework(value?.value ? value.value : ALL_FRAMEWORKS)
              }
              options={[
                { value: ALL_FRAMEWORKS, label: 'All frameworks' },
                ...uniq(tables).map((table) => {
                  return { value: table, label: table };
                }),
              ]}
              size="md"
            />
          </Box>
          <Box flex={0.75}>
            <Select<{ value: string; label: string }, true>
              ref={selectRef}
              components={{
                ValueContainer: ValueContainer,
                Option: Option,
              }}
              placeholder={'All companies'}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              isMulti
              defaultValue={[]}
              onChange={(options) => {
                setSelectedCompanies(options?.map((opt) => opt));
              }}
              options={[
                ...portfolioCompanies
                  .filter((pc) => !pc.estimateCompany?.id)
                  .map((c) => {
                    return { value: c.company?.id ?? '', label: c.company?.name ?? '' };
                  }),
              ]}
              size="md"
            />
          </Box>
          {(cash > 0 || sovereigns > 0) && (
            <HStack>
              <Box>
                <CashSovereignSelectorMenu
                  includeSovereigns={includeSovereigns}
                  includeCash={includeCash}
                  setIncludeSovereigns={setIncludeSovereigns}
                  setIncludeCash={setIncludeCash}
                  cash={cash}
                  sovereigns={sovereigns}
                  reportPeriod={reportPeriod}
                />
              </Box>
              <IconButton
                size="s"
                variant="ghost"
                icon={<HelpIcon />}
                aria-label="Info button"
                onClick={() => openArticle(ARTICLE_REPORTING_CASH_SOVEREIGNS_KPI.slug)}
              />
            </HStack>
          )}
          <Box flex={1} />
        </HStack>
        <HStack alignSelf="start">
          {selectedCompanies?.map((company) => {
            return (
              <HStack
                key={company.value}
                spacing="6px"
                p="7px"
                borderRadius="8px"
                border="1px solid"
                borderColor="border.default"
              >
                <Typography variant="bodyStrong" pl="8px" color="text.default">
                  {company.label}
                </Typography>
                <Center
                  cursor="pointer"
                  onClick={() => {
                    selectRef?.current?.removeValue({
                      value: company.value,
                      label: company.label,
                    });
                  }}
                  w="20px"
                  h="20px"
                >
                  <RemoveIcon boxSize="12px" color="text.muted" />
                </Center>
              </HStack>
            );
          })}
          {selectedCompanies.length > 1 && (
            <Button
              variant="ghost"
              leftIcon={<RemoveIcon boxSize="16px" />}
              onClick={() => {
                selectRef?.current?.clearValue();
              }}
            >
              Clear All
            </Button>
          )}
        </HStack>
      </VStack>
    );
  }
);

const PortfolioPaiView = ({
  report,
  reportingYear,
  paiProgressPerCompany,
}: {
  report: PaiPortfolioReport;
  reportingYear: string;
  paiProgressPerCompany: Record<string, number>;
}) => {
  const [, year] = splitQuarterYear(reportingYear);
  const { t } = useTranslation(['common', 'pai']);
  const { portfolioId } = useParams();
  const { reportPeriod } = useCurrentReportPeriod();
  const { getPortfolio, loading } = usePortfolios();
  const portfolio = getPortfolio(portfolioId ?? '', reportingYear);
  const { results } = usePaiReportAnswers(portfolioId ?? '', Number(year), reportPeriod);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedCompanies, setSelectedCompanies] = useState<{ value: string; label: string }[]>(
    []
  );
  const [selectedFramework, setSelectedFramework] = useState<string>('All frameworks');

  const {
    includeSovereigns,
    setIncludeSovereigns,
    includeCash,
    setIncludeCash,
    sovereignsStored,
    cashStored,
    cash,
    sovereigns,
  } = useCashAndSovereigns({
    portfolioId,
    yearAndQuarter: reportingYear,
    reportPeriod,
    defaultValue: false,
  });

  const showInProgressBanner = useMemo(() => {
    return Object.values(paiProgressPerCompany).some((value) => value < 1);
  }, [paiProgressPerCompany]);
  const tables = useMemo(
    () => report.selectedIndicators.map((s) => s.indicator.category.table.title),
    [report]
  );

  const resultsFilter = useMemo(() => getResultsFilter(selectedCompanies), [selectedCompanies]);
  const filteredResults = useMemo(() => {
    return (results ?? []).filter(resultsFilter);
  }, [resultsFilter, results]);

  const reportFilter = useMemo(
    () => getReportFilter(searchValue, selectedFramework, reportPeriod, selectedCompanies),
    [searchValue, selectedFramework, reportPeriod, selectedCompanies]
  );
  const filteredReport = useMemo(() => {
    return (report.selectedIndicators ?? []).filter(reportFilter);
  }, [reportFilter, report]);

  if (loading)
    return (
      <Box w={'100%'} h={'100%'} p={'16px'}>
        <CelsiaSpinner />
      </Box>
    );
  return (
    <HStack spacing="16px" width="100%" alignItems="flex-start" pb="16px" flexGrow="1">
      <VStack spacing="16px" alignItems="flex-start" width="100%" flexGrow="1">
        {report?.dueDates?.year && (
          <Alert
            status="neutral"
            closable={false}
            title={`Note that some indicators are collected yearly. Deadline for them is ${formatDateToDDMMYYYY(
              formatDate(new Date(report?.dueDates?.year))
            )}`}
          />
        )}
        {showInProgressBanner && (
          <Box width="100%">
            <Infobox
              status="info"
              closable={false}
              title={t('pai:inProgressWarning.title')}
              description={t('pai:inProgressWarning.description')}
            />
          </Box>
        )}
        <HStack justifyContent="space-between" width="100%">
          <FilterBar
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            selectedCompanies={selectedCompanies}
            setSelectedCompanies={setSelectedCompanies}
            selectedFramework={selectedFramework}
            setSelectedFramework={setSelectedFramework}
            tables={tables}
            reportingYear={reportingYear}
            includeSovereigns={includeSovereigns}
            setIncludeSovereigns={setIncludeSovereigns}
            includeCash={includeCash}
            setIncludeCash={setIncludeCash}
            cash={cashStored}
            sovereigns={sovereignsStored}
          />
          <PaiExport
            onClick={() => sendHotjarEvent('click_pai_aggregated_export')}
            selectedIndicators={filteredReport}
            results={filteredResults}
            reportingYear={reportingYear}
            cashAndSovereigns={{ cash, sovereigns }}
          />
        </HStack>
        {filteredReport.length ? (
          <IndicatorsResultsTable
            selectedIndicators={filteredReport}
            results={filteredResults}
            reportPeriod={reportPeriod}
            portfolioCompanies={portfolio.portfolioCompanies}
            cashAndSovereigns={{ cash, sovereigns }}
          />
        ) : (
          <Box w="100%" flexGrow="1">
            <EmptyState
              title={t('common:search.filter.emptyTitle')}
              description={t('common:search.filter.emptyDescription')}
              callToAction={{
                text: t('common:search.filter.emptyBtn'),
                variant: 'secondary',
                onClick: () => {
                  setSearchValue('');
                  setSelectedFramework(ALL_FRAMEWORKS);
                },
                leftIcon: <RefreshIcon boxSize="16px" />,
              }}
              component={true}
            />
          </Box>
        )}
      </VStack>
    </HStack>
  );
};

export const PortfolioPai = ({
  reportingYear,
  isAuditor,
  paiProgressPerCompany,
}: {
  reportingYear: string;
  isAuditor?: boolean;
  paiProgressPerCompany: Record<string, number>;
}) => {
  const { t } = useTranslation('pai');
  const { company } = useCurrentCompany();
  const navigate = useNavigate();
  const { portfolioId } = useParams();
  const [quarter, year] = splitQuarterYear(reportingYear);
  const { data, loading } = useGetPaiPortfolioReportQuery({
    variables: {
      portfolioId,
      year: Number(year),
      quarter: quarter as QuarterEnum_Enum_,
    },
    skip: !portfolioId,
    context: portfolioOwnerContext,
  });

  const report = data?.report?.[0];

  if (!company?.hasPaiAccess) {
    return (
      <Box w="100%" h="100%" p="16px">
        <EmptyState
          title={t('pai:portfolioNoAccess.title')}
          description={<Typography pb="96px">{t('pai:portfolioNoAccess.description')}</Typography>}
          icon={<PaiIndicatorsIllustration boxSize="80px" />}
          component={true}
        />
      </Box>
    );
  }
  return (
    <Skeleton isLoaded={!loading} flexGrow="1" display="flex">
      <VStack width="100%" alignItems="center" paddingTop="16px" flexGrow="1">
        {report ? (
          <PortfolioPaiView
            report={report}
            reportingYear={reportingYear}
            paiProgressPerCompany={paiProgressPerCompany}
          />
        ) : (
          <Box w="100%" h="100%">
            <EmptyState
              title={'No PAI Indicators selected yet'}
              description={
                <VStack pb="96px">
                  <Typography>
                    {
                      'Start by completing the PAI part of your portfolio by adding and choosing indicators and time periods..'
                    }
                  </Typography>
                  <Button
                    variant="ghost"
                    leftIcon={<EditIcon />}
                    onClick={() => navigate(`${reportingYear}/edit`)}
                    isDisabled={isAuditor}
                  >
                    Edit
                  </Button>
                </VStack>
              }
              icon={<PaiIndicatorsIllustration boxSize="80px" />}
              component={true}
            />
          </Box>
        )}
      </VStack>
    </Skeleton>
  );
};
