import { CheckIcon, ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Circle, Collapse, HStack, Skeleton, VStack, useColorMode } from '@chakra-ui/react';
import { Button, IconButton, Tag } from 'Atoms';
import { Typography, colors } from 'Tokens';
import { ArrowUpRightIcon } from 'Tokens/Icons/Direction';
import { LinkIcon, PrivateIcon, PublicIcon } from 'Tokens/Icons/Function';
import { QuestionMarkIcon } from 'Tokens/Icons/Status';
import React, { ReactNode, Suspense, memo, useMemo, useRef, useState } from 'react';
import { MetricViewEnums, TargetFieldsEnum } from '../DisclosureRequirements';
import { useParams } from 'react-router-dom';
import { useCurrentCompany } from 'utils/hooks';
import { DataCollectionLevel } from '../DataCollection';
import { PieChart } from 'react-minimal-pie-chart';
import { getMilestoneValue, useFirstViewportEntry } from './Report.hooks';
import {
  ReportTargetQuestionsQuery_,
  TargetFieldsFragment_,
  TargetQuestionFieldsFragment_,
} from 'models';
import {
  actionsFinancialMDR,
  actionsScopeMDR,
  targetsMDR,
} from '../DisclosureRequirements/MDR/MDRData';
import { mapUnitToCompanyCurrency } from 'containers/Esrs/utils';

export enum CompletionStatus {
  complete = 'Completed',
  hidden = 'Hidden',
  incomplete = 'Incomplete',
}

export const COMPLETION_STATES = {
  [CompletionStatus.complete]: {
    bg: 'bg.success',
    icon: <CheckIcon color="text.success" boxSize="12px" />,
    text: 'Completed (all data points provided)',
  },
  [CompletionStatus.hidden]: {
    bg: 'bg.warning',
    icon: <PrivateIcon color="text.warning" />,
    text: 'Completed, but hidden from the report',
    textIncomplete: 'Incomplete and hidden from the report',
  },
  [CompletionStatus.incomplete]: {
    bg: 'bg.unknown',
    icon: <QuestionMarkIcon color="text.muted" />,
    text: 'Incomplete (not all data points provided)',
  },
};

export const MARKED_GENERAL = ['E1-GOV-3', 'G1-GOV-1'];

export const ClimateTargetQuestionRefs = [
  'esrs:DescriptionOfHowItHasBeenEnsuredThatBaselineValueIsRepresentativeInTermsOfActivitiesCoveredAndInfluencesFromExternalFactorsExplanatory',
  'esrs:DescriptionOfHowNewBaselineValueAffectsNewTargetItsAchievementAndPresentationOfProgressOverTimeExplanatory',
  'esrs:DisclosureOfPastProgressMadeInMeetingTargetBeforeCurrentBaseYearExplanatory',
  'esrs:TargetIsDerivedUsingSectoralDecarbonisationPathway',
  'esrs:DisclosureOfFrameworkAndMethodologyThatHasBeenUsedToDetermineGHGEmissionReductionTargetUnderlyingClimateAndPolicyScenariosForTargetHowFutureDevelopmentsHaveBeenConsideredAndHowTheseWillPotentiallyImpactGHGEmissionsAndEmissionsReductionsExplanatory',
  'esrs:ExplanationOfHowTargetsAreCompatibleWithLimitingOfGlobalWarmingToOneAndHalfDegreesCelsiusInLineWithParisAgreementExplanatory',
  'esrs:GHGEmissionReductionTargetIsScienceBasedAndCompatibleWithLimitingGlobalWarmingToOneAndHalfDegreesCelsius',
  'esrs:TargetHasBeenExternallyAssured',
];

export const toRomanNumeral = (num: number) => {
  const numbers = ['0', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'];
  return numbers[num];
};

export const ReportProgressPieChart = memo(({ collectedValue }: { collectedValue: number }) => {
  const { colorMode } = useColorMode();
  const isDarkMode = useMemo(() => colorMode === 'dark', [colorMode]);
  const color = useMemo(() => (isDarkMode ? '_dark' : 'default'), [isDarkMode]);
  return (
    <Circle size="16px" alignItems="center">
      <PieChart
        lineWidth={16}
        data={[
          {
            title: 'Collected',
            value: collectedValue,
            color: colors['bg.progress'][color],
          },
          {
            title: 'Missing',
            value: 100 - collectedValue,
            color: colors['bg.unknown'][color],
          },
        ]}
      />
    </Circle>
  );
});

export const ReportStatusIcon = memo(({ status }: { status: CompletionStatus }) => {
  return (
    <VStack
      bg={COMPLETION_STATES[status].bg}
      borderRadius="4px"
      boxSize="20px"
      textAlign="center"
      boxSizing="border-box"
      justifyContent="center"
    >
      {COMPLETION_STATES[status].icon}
    </VStack>
  );
});

const ReportStatusBarSource = ({
  standardRef,
  standardName,
  editAnswer,
}: {
  standardRef: string;
  standardName: string;
  editAnswer: () => void;
}) => (
  <HStack gap="8px">
    <Typography variant="body" w="132px">
      Source:
    </Typography>
    <Tag
      variant="ghost"
      bgColor="bg.interactive"
      borderRadius="4px"
      size="sm"
      cursor="pointer"
      padding="0px 4px"
      onClick={editAnswer}
    >
      <HStack gap="4px">
        <LinkIcon />
        <Typography variant="body">
          {standardRef} {standardName}
        </Typography>
      </HStack>
    </Tag>
  </HStack>
);

const ReportStatusBarStatus = ({
  status,
  completed,
  isLoading,
  isMetric,
}: {
  status: CompletionStatus;
  completed: boolean;
  isLoading?: boolean;
  isMetric: boolean;
}) => (
  <HStack gap="8px">
    <Typography variant="body" w="132px">
      Status:
    </Typography>
    {isMetric ? (
      <Skeleton isLoaded={!isLoading}>
        <Typography variant="body">
          {status === CompletionStatus.hidden
            ? completed
              ? COMPLETION_STATES[status].text
              : COMPLETION_STATES[status].textIncomplete
            : COMPLETION_STATES[status].text}
        </Typography>
      </Skeleton>
    ) : (
      <Typography variant="body">{completed ? 'Complete' : 'Incomplete'}</Typography>
    )}
  </HStack>
);

export const ReportStatusBar = memo(
  ({
    status,
    metric,
    standardRef,
    standardName,
    completed,
    drRef,
    dataCollection,
    isMetricHidden,
    isLoading,
    onHide,
    shouldHideCompletion = false,
  }: {
    status: CompletionStatus;
    metric: {
      reference: string;
    };
    standardRef: string;
    completed: boolean;
    standardName: string;
    isMetricHidden: boolean;
    drRef: string;
    isLoading: boolean;
    onHide: (isHidden: boolean) => void;
    dataCollection: string | null;
    shouldHideCompletion?: boolean;
  }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { esrsAssessmentId = '' } = useParams();
    const company = useCurrentCompany();

    const editAnswer = () => {
      if (!!metric) {
        window.open(
          `/${company?.company?.id}/esrs/${esrsAssessmentId}/standard/${standardRef}/disclosure-requirement/metric/${drRef}/${MetricViewEnums.dataInput}?metricRef=${metric.reference}`
        );
      }
    };

    return (
      <VStack w="100%" alignItems="start" gap="0px" userSelect="none">
        <HStack justifyContent="space-between" w="100%">
          <HStack cursor="pointer" onClick={() => setIsOpen(!isOpen)}>
            {!shouldHideCompletion && (
              <Skeleton isLoaded={!isLoading}>
                <ReportStatusIcon status={status} />
              </Skeleton>
            )}
            <HStack gap="2px">
              <Typography variant="bodyStrong">{status}</Typography>
              {isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
            </HStack>
          </HStack>
          <HStack gap="2px">
            <IconButton
              isLoading={isLoading}
              aria-label="hide"
              variant="ghost"
              size="sm"
              icon={isMetricHidden ? <PrivateIcon /> : <PublicIcon />}
              onClick={() => onHide(!isMetricHidden)}
            />
            <IconButton
              aria-label="edit"
              size="sm"
              variant="ghost"
              icon={<ArrowUpRightIcon />}
              onClick={editAnswer}
              tooltipMaxWidth="200px"
              tooltipLabel={
                dataCollection === DataCollectionLevel.company
                  ? 'Open the source and edit'
                  : 'Open the source'
              }
            />
          </HStack>
        </HStack>

        <Box p="0px">
          <Collapse in={isOpen} animateOpacity>
            <VStack alignItems="start" w="100%" p="8px 0px">
              <ReportStatusBarStatus
                status={status}
                completed={completed}
                isLoading={isLoading}
                isMetric={true}
              />
              <ReportStatusBarSource
                standardRef={standardRef}
                standardName={standardName}
                editAnswer={editAnswer}
              />
            </VStack>
          </Collapse>
        </Box>
      </VStack>
    );
  }
);

export const QuestionStatusBar = memo(
  ({
    status,
    link,
    completed,
    standardRef,
    standardName,
  }: {
    status: CompletionStatus;
    completed: boolean;
    link: string;
    standardRef: string;
    standardName: string;
  }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <VStack w="100%" alignItems="start" gap="0px" userSelect="none">
        <HStack justifyContent="space-between" w="100%">
          <HStack cursor="pointer" onClick={() => setIsOpen(!isOpen)}>
            <ReportStatusIcon status={status} />
            <HStack gap="2px">
              <Typography variant="bodyStrong">{status}</Typography>
              {isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
            </HStack>
          </HStack>
          <IconButton
            aria-label="edit"
            size="sm"
            variant="ghost"
            icon={<ArrowUpRightIcon />}
            onClick={() => window.open(link)}
          />
        </HStack>

        <Box p="0px">
          <Collapse in={isOpen} animateOpacity>
            <VStack alignItems="start" w="100%" p="8px 0px">
              <ReportStatusBarStatus status={status} completed={completed} isMetric={false} />
              <ReportStatusBarSource
                standardRef={standardRef}
                standardName={standardName}
                editAnswer={() => window.open(link)}
              />
            </VStack>
          </Collapse>
        </Box>
      </VStack>
    );
  }
);

export const RenderOnViewportEntry = ({
  children,
  threshold = 0,
  root = null,
  height,
  rootMargin = '0px 0px 0px 0px',
}: {
  children: React.ReactNode;
  threshold: number;
  height?: number;
  root?: Element | Document | null;
  rootMargin?: string;
}) => {
  const ref = useRef(null);
  const entered = useFirstViewportEntry({ threshold, root, rootMargin }, ref);

  return (
    <Box style={{ minHeight: height ?? '200px' }} ref={ref} w="100%">
      <Suspense fallback={<Skeleton height="200px" />}>{entered ? children : null}</Suspense>
    </Box>
  );
};

export const NoAnswerBox = ({
  isPreview,
  provideAnswer,
}: {
  isPreview: boolean;
  provideAnswer: () => void;
}) => (
  <VStack gap="12px" alignItems="start">
    <Typography variant="body" color="text.hint">
      No answer has been provided yet
    </Typography>
    {!isPreview && (
      <Button variant="secondary" size="sm" onClick={provideAnswer}>
        Provide answer
      </Button>
    )}
  </VStack>
);

const formatMDRAnswer = (
  answer: string,
  isDocx: boolean,
  isPreview?: boolean
): JSX.Element | string => {
  if (isDocx) return answer;
  return (
    <Typography variant="bodyStrong" as="span" color={isPreview ? '' : 'text.hint'}>
      {answer}
    </Typography>
  );
};

const getClimateChangeBaselineMDRT = (
  targetQuestions: TargetQuestionFieldsFragment_[],
  target: TargetFieldsFragment_
) => {
  const targetQuestionBaselineRefs = ClimateTargetQuestionRefs.slice(0, 3);

  const baselineTargetQuestions =
    targetQuestions.filter((question) => targetQuestionBaselineRefs.includes(question.reference)) ??
    [];
  const baselineChildren = baselineTargetQuestions.map((question) => ({
    title: question.title,
    type: question.questionType,
    field: TargetFieldsEnum.baseline,
    unit: question.unit,
    isChild: true,
    value: target.answers?.[question.reference]?.value,
  }));
  return baselineChildren;
};

const getClimateChangeScientificEvidenceMDRT = (
  targetQuestions: TargetQuestionFieldsFragment_[],
  target: TargetFieldsFragment_
) => {
  const targetQuestionScientificEvidenceRefs = ClimateTargetQuestionRefs.slice(3);
  const scientificEvidenceTargetQuestions =
    targetQuestions.filter((question) =>
      targetQuestionScientificEvidenceRefs.includes(question.reference)
    ) ?? [];

  const scientificEvidenceChildren = scientificEvidenceTargetQuestions.map((question) => ({
    title: question.title,
    type: question.questionType,
    field: TargetFieldsEnum.isEvidenceScientific,
    unit: question.unit,
    isChild: true,
    value: target.answers?.[question.reference]?.value,
  }));
  return scientificEvidenceChildren;
};

export const adjustMDRReportData = (data: {
  target: TargetFieldsFragment_;
  isDocx: boolean;
  isClimateChange: boolean;
  isPreview?: boolean;
  targetQuestions?: ReportTargetQuestionsQuery_ | undefined;
  flatTargetQuestions?: TargetQuestionFieldsFragment_[] | undefined;
  companyCurrency?: string;
}) => {
  const {
    target,
    isDocx,
    isClimateChange,
    isPreview = false,
    targetQuestions,
    flatTargetQuestions,
  } = data;

  // Baseline MDRT
  const baseline = target.keyResults.find((kr) => kr.reportingUnitId === null);
  const baselineExists = baseline?.baseline !== undefined && baseline?.baseline !== 'null';
  const baselineYear = !!baseline?.baseYear
    ? new Date(baseline.baseYear).getFullYear()
    : formatMDRAnswer('Baseline year N/A', isDocx, isPreview);
  const baselineValue = baselineExists
    ? baseline?.baseline
    : formatMDRAnswer('Baseline value N/A', isDocx, isPreview);

  const baselineMDR = {
    title: isDocx ? (
      `The baseline year from which progress is measured ${baselineYear} with a corresponding baseline value of ${baselineValue}`
    ) : (
      <Typography variant="bodyStrong" as="span">
        The baseline year from which progress is measured {baselineYear} with a corresponding
        baseline value of {baselineValue}
      </Typography>
    ),
    field: 'baseline' as TargetFieldsEnum,
    value: baseline?.baseline,
    type: '',
    learnMore: '',
    hideAnswer: true,
  };

  // Milestone MDRT
  const milestoneYears = target.milestones.map((m) => m.year) ?? [];
  const finalYear = Math.max(...milestoneYears);
  const milestoneValue = milestoneYears.length ? getMilestoneValue(target, finalYear) : undefined;

  const milestoneYear = !!milestoneYears.length
    ? finalYear
    : formatMDRAnswer('Milestone year N/A', isDocx, isPreview);

  const milestoneValueText =
    typeof milestoneValue === 'number' && baselineExists
      ? `${milestoneValue} ${mapUnitToCompanyCurrency(target.metric?.unitOfMeasurement ?? 't', data.companyCurrency ?? '')}`
      : formatMDRAnswer('Milestone value N/A', isDocx, isPreview);

  const direction =
    typeof milestoneValue === 'number'
      ? milestoneValue > 0
        ? 'increase'
        : milestoneValue === 0
          ? 'maintain'
          : 'reduce'
      : formatMDRAnswer('N/A', isDocx, isPreview);

  const milestoneYearsText = milestoneYears.map((year) => {
    const value = getMilestoneValue(target, year);

    const mValue =
      typeof value === 'number' && baselineExists
        ? `${value} ${mapUnitToCompanyCurrency(target.metric?.unitOfMeasurement ?? 't', data.companyCurrency ?? '')} by ${year}`
        : formatMDRAnswer(`Milestone value N/A by ${year}`, isDocx, isPreview);
    return mValue;
  });

  const joinedElements: ReactNode = milestoneYearsText.reduce<ReactNode[]>((prev, curr, index) => {
    if (index === 0) {
      return [curr];
    } else {
      return [...prev, ', ', curr];
    }
  }, []);

  const milestonesText = milestoneYears.length ? (
    isDocx ? (
      `The milestones are ${milestoneYearsText.join(', ')}`
    ) : (
      <Typography variant="bodyStrong" as="span">
        The milestones are {joinedElements}.
      </Typography>
    )
  ) : (
    ''
  );

  const timeMDR = {
    title: isDocx ? (
      `The time period the target applies. The target is to ${direction} ${target.metric?.title} to ${milestoneValueText} by ${milestoneYear}. ${milestonesText}`
    ) : (
      <Typography variant="bodyStrong" as="span">
        The time period the target applies. The target is to {direction} {target.metric?.title} to{' '}
        {milestoneValue} by {milestoneYear}. {milestonesText}
      </Typography>
    ),
    field: 'baseline' as TargetFieldsEnum,
    value: milestoneValue,
    type: '',
    learnMore: '',
    hideAnswer: true,
  };

  // Target questions in MDRT
  const flattendTargetQuestions = flatTargetQuestions
    ? flatTargetQuestions
    : [
        ...(targetQuestions?.singleQuestions ?? []),
        ...(targetQuestions?.groups.flatMap((tq) => tq.targetQuestions) ?? []),
      ];
  const baselineChildren = isClimateChange
    ? getClimateChangeBaselineMDRT(flattendTargetQuestions, target)
    : [];
  const scientificEvidenceChildren = isClimateChange
    ? getClimateChangeScientificEvidenceMDRT(flattendTargetQuestions, target)
    : [];

  // Hardcoded order
  const reportTargetsMDR = [
    ...targetsMDR.slice(0, 3),
    timeMDR,
    baselineMDR,
    ...baselineChildren,
    ...targetsMDR.slice(3, 6),
    ...scientificEvidenceChildren,
    targetsMDR[6],
    ...targetsMDR.slice(7, 9),
    targetsMDR[9],
    { ...targetsMDR[9].children?.[0], isChild: true },
    ...targetsMDR.slice(10),
  ];

  return reportTargetsMDR;
};

export const getReportMDRA = () => {
  return [
    // Scope
    ...actionsScopeMDR.slice(0, 3),
    actionsScopeMDR[3],
    { ...actionsScopeMDR[3]?.children?.[0], isChild: true },
    { ...actionsScopeMDR[3]?.children?.[1], isChild: true },
    { ...actionsScopeMDR[3]?.children?.[2], isChild: true },
    ...actionsScopeMDR.slice(4),
    // Financials
    ...actionsFinancialMDR.slice(0, 6),
    actionsFinancialMDR[6],
    { ...actionsFinancialMDR[6]?.children?.[0], isChild: true },
    { ...actionsFinancialMDR[6]?.children?.[1], isChild: true },
    { ...actionsFinancialMDR[6]?.children?.[2], isChild: true },
    actionsFinancialMDR[7],
    { ...actionsFinancialMDR[7]?.children?.[0], isChild: true },
    { ...actionsFinancialMDR[7]?.children?.[1], isChild: true },
    { ...actionsFinancialMDR[7]?.children?.[2], isChild: true },
  ];
};
